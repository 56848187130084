<template>
  <b-modal
    id="modal-note-installment"
    ok-only
    ok-title="Fechar"
    centered
    size="xl"
    title="Notas"
  >
    <b-table-simple
      responsive
      striped
      stacked="md"
      noDataText="Você ainda não possui clientes cadastrados."
      class="text-center mt-2"
    >
      <b-thead>
        <b-th>Nota</b-th>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td> Passar notas </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
		
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
		BModal
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
 