<template>
  <b-card>
    <div>
      <b-row>
        <div v-if="!showNegotiation">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showNegotiation = true"
            title="Total"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="DollarSignIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showNegotiation = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

        <div v-if="!showTitles">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showTitles = true"
            title="Aberto"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="CreditCardIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showTitles = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

        <div v-if="!showClients">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showClients = true"
            title="Atraso"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="AlertTriangleIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showClients = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

        <div v-if="!showFilter">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showFilter = true"
            title="Filtro"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SlidersIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showFilter = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

      </b-row>

      <div>
        <b-row class="justify-content-md-center">
          <b-col md="6" xl="4" class="p-1" v-show="showNegotiation">
            <statistic-card-vertical
              hide-chart
              color="info"
              icon="DollarSignIcon"
              statistic="R$ 0,00"
              statistic-title="Total parcelado"
            />
          </b-col>
          <b-col md="6" xl="4" class="p-1" v-show="showTitles">
             <statistic-card-vertical
              hide-chart
              color="success"
              icon="CreditCardIcon"
              statistic="0"
              statistic-title="Parcelamentos em aberto"
            />
          </b-col>
          <b-col md="6" xl="4" class="p-1" v-show="showClients">
            <statistic-card-vertical
              hide-chart
              color="warning"
              icon="AlertTriangleIcon"
              statistic="0"
              statistic-title="Parcelamentos com atraso"
            />
          </b-col>
        </b-row>
      </div>

      <div v-show="showFilter">
        <hr>
        <h4>Filtros</h4>
        <b-row>
          <b-col md="6" xl="3" class="p-1">
            <label> Tipo contrato </label>
            <b-form-select v-model="typeContract" :options="typeContractOptions" />
          </b-col>
          <b-col md="6" xl="3" class="p-1">
            <label> Situação contrato </label>
            <b-form-select v-model="situationContract" :options="situationContractOptions" />
          </b-col>
          <b-col md="6" xl="3" class="p-1">
            <label> Situação pagamento </label>
            <b-form-select v-model="situationPayment" :options="situationPaymentOptions" />
          </b-col>
        </b-row>
      </div>


      <!-- Listagem - Table -->
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
      <b-table-simple
        striped
        stacked="md"
        noDataText="Você ainda não possui clientes cadastrados."
        class="mt-2 responsive text-center"
        id="tableInstallment"
        :item="fetchInstallment"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <b-thead>
          <b-th> Beneficiário </b-th>
          <b-th> Método </b-th>
          <b-th> Nº Parcelas </b-th>
          <b-th> Valor parcelado </b-th>
          <b-th> Descontos </b-th>
          <b-th> Acréscimos </b-th>
          <b-th> Ações </b-th>
        </b-thead>
        <b-tbody>
          <b-tr :key="indextr" v-for="(tr, indextr) in installments">
            <b-td> {{ tr.tenant.company_name }} </b-td>
            <b-td> {{ tr.installment_type.name }} </b-td>
            <b-td> {{ tr.amount }} </b-td>
            <b-td> {{ tr.installment_value | moneyFormat }} </b-td>
            <b-td> {{ tr.discount | moneyFormat }} </b-td>
            <b-td> {{ tr.addition | moneyFormat }} </b-td>
            <b-td>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
                v-b-modal.modal-note-installment
              >
                <feather-icon icon="BookmarkIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      </b-overlay>
      <notes />
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableInstallment"
        class="mt-1"
      />
    </div>
  </b-card>
</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BButton } from "bootstrap-vue";
import Notes from '@/components/installment/Notes.vue';
// import vSelect from "vue-select";

export default {
  components: {
    BButton,
    StatisticCardVertical,
    Notes
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      search: null,

      isLoading: true,

      showNegotiation: false,

      showTitles: false,

      showClients: false,

      showFilter: false,

      selected: null,
      options: [
        { value: null, text: "Status" },
        { value: "a", text: "Finalizada" },
        { value: "b", text: "Pendente" },
        { value: "c", text: "Não paga" },
      ],

      typeContract: 'T',
      typeContractOptions: [
        { value: 'T', text: "Todos" },
        { value: "f", text: "Pessoa física" },
        { value: "j", text: "Pessoa jurídica" },
      ],

      situationContract: 'T',
      situationContractOptions: [
        { value: 'T', text: "Todos" },
        { value: "1", text: "Pago" },
        { value: "0", text: "Em aberto" },
      ],

      situationPayment: 'T',
      situationPaymentOptions: [
        { value: 'T', text: "Todos" },
        { value: "ATRASADOS", text: "Atrasado" },
        { value: "EM_DIA", text: "Em dia" },
      ],
    };
  },
  computed: {
    ...mapState("installments", ["installments", "pagination"])
  },
  methods: {
    fetchInstallment(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("installments/fetchInstallment", {
          paginated: paginated,
          page: page,
          generic: this.search,
          contract_type: this.typeContract,
          contract_situation: this.situationContract,
          status_payment: this.situationPayment,
          perPage: this.perPage,
        })

        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchInstallment(true, value);
    },
  },
  created() {
    this.fetchInstallment();
  },
   watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchInstallment(true, 1);
    },
    typeContract(val) {
      this.currentPage = 1;
      this.fetchInstallment(true, 1);
    },
    situationContract(val) {
      this.currentPage = 1;
      this.fetchInstallment(true, 1);
    },
    situationPayment(val) {
      this.currentPage = 1;
      this.fetchInstallment(true, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
